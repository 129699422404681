import {Link} from 'react-router-dom'
import React, {Component} from 'react';
import './LeftSideBar.css';
import {getRouteList, routeHome} from '../../../utils/RouterUtils';
import {Image, Layout, Menu} from "antd";
import {HomeOutlined, LogoutOutlined, ShopOutlined, UserOutlined} from "@ant-design/icons";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../../bloc/SettingsBloc";
import AuthBloc from "../../../bloc/AuthBloc";
import {MenuNames} from "../../../utils/Constants";
import Images from "../../../utils/Images";
import {Box} from "../../shared/Box";

const { SubMenu } = Menu;
const { Header, Footer, Sider, Content } = Layout;


class LeftSideBar extends Component {

    handleItemClick = (e, {name}) => this.setState({activeItem: name})

    render() {
        return (
            <BlocBuilder
                subject = {SettingsBloc.instance.sideBarCollapsed}
                builder = {(snapshot) => {
                    return   <Sider trigger={null} collapsible collapsed={snapshot.data}>

                        <div className="side-logo">

                            <Image
                                preview={false}
                                width={50}
                                src={Images.logoUnilever}
                            />
                            <Image
                                preview={false}
                                width={60}
                                src={Images.logo}
                            />
                        </div>
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            {this.prepareMenu(routes.home)}
                            {this.prepareMenu(routes.observation)}
                            {AuthBloc.instance.isAdmin() && <SubMenu key="user" icon={<UserOutlined />} title="User">
                                {this.prepareSubMenu(routes.user)}
                                {this.prepareSubMenu(routes.user_group)}
                                {this.prepareSubMenu(routes.department)}

                            </SubMenu>}
                            {AuthBloc.instance.isAdmin() && <SubMenu key="locations" icon={<ShopOutlined />} title="Locations">
                                {this.prepareSubMenu(routes.region)}
                                {this.prepareSubMenu(routes.area)}
                                {this.prepareSubMenu(routes.territory)}
                            </SubMenu>}
                            {AuthBloc.instance.isAdmin() && <SubMenu key="items" icon={<ShopOutlined />} title="Observation Items">
                                {this.prepareSubMenu(routes.observation_title)}
                                {this.prepareSubMenu(routes.observation_subtitle)}
                                {this.prepareSubMenu(routes.observation_type)}
                                {this.prepareSubMenu(routes.person_type)}
                            </SubMenu>}

                            <Menu.Item key={"logout"} icon={<LogoutOutlined />} onClick={()=> AuthBloc.instance.logout()}>
                                {"Logout"}
                            </Menu.Item>
                        </Menu>
                    </Sider>
                }}/>
        )
    }

    prepareSubMenu = (item) => {
        return <Menu.Item key={item.key}>
            <Link key={item.key+"-link"} to={item.to}>{item.label}</Link>
        </Menu.Item>
    };

    prepareMenu = (item) => {
        return <Menu.Item key={item.key} icon={item.icon}>
            <Link key={item.key+"-link"} to={item.to}>{item.label}</Link>
        </Menu.Item>
    };
}

export default LeftSideBar;

const routes = {
    home: {key: MenuNames.home.lower, label: MenuNames.home.upper, to: routeHome, icon: <HomeOutlined />},
    region: {key: MenuNames.region.lower, label: MenuNames.region.upper, to: getRouteList(MenuNames.region.lower), icon: <HomeOutlined />},
    area: {key: MenuNames.area.lower, label: MenuNames.area.upper, to: getRouteList(MenuNames.area.lower), icon: <HomeOutlined />},
    territory: {key: MenuNames.territory.lower, label: MenuNames.territory.upper, to: getRouteList(MenuNames.territory.lower), icon: <HomeOutlined />},
    user: {key: MenuNames.user.lower, label: MenuNames.user.upper, to: getRouteList(MenuNames.user.lower), icon: <HomeOutlined />},
    user_group: {key: MenuNames.user_group.lower, label: MenuNames.user_group.upper, to: getRouteList(MenuNames.user_group.lower), icon: <HomeOutlined />},
    observation_type: {key: MenuNames.observation_type.lower, label: MenuNames.observation_type.upper, to: getRouteList(MenuNames.observation_type.lower), icon: <HomeOutlined />},
    observation_title: {key: MenuNames.observation_title.lower, label: MenuNames.observation_title.upper, to: getRouteList(MenuNames.observation_title.lower), icon: <HomeOutlined />},
    observation_subtitle: {key: MenuNames.observation_subtitle.lower, label: MenuNames.observation_subtitle.upper, to: getRouteList(MenuNames.observation_subtitle.lower), icon: <HomeOutlined />},
    person_type: {key: MenuNames.person_type.lower, label: MenuNames.person_type.upper, to: getRouteList(MenuNames.person_type.lower), icon: <HomeOutlined />},
    department: {key: MenuNames.department.lower, label: MenuNames.department.upper, to: getRouteList(MenuNames.department.lower), icon: <HomeOutlined />},
    observation: {key: MenuNames.observation.lower, label: MenuNames.observation.upper, to: getRouteList(MenuNames.observation.lower), icon: <HomeOutlined />},
}