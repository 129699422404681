import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Button, Col, Form, Row, Select, Spin, Upload} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {BASEURL, MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {areaFilterParams} from "../area/AreaConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {InputTextArea} from "../shared/FormComponent/InputTextArea";
import {UploadOutlined} from "@ant-design/icons";
import {regionFilterParams} from "../region/RegionConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {observation_subtitleFilterParams} from "../observation_subtitle/ObservationSubtitleConstants";
import axiosWrapper from "../../utils/AxiosWrapper";
import {IsLoading} from "../shared/IsLoading";

export class ObservationCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        console.log(AuthBloc.instance.user.getValue());

        this.state = {
            description: InputFields.description,
            is_action_taken: InputFields.is_action_taken,
            action_taken: InputFields.action_taken,
            address: InputFields.address,
            type_id: InputFields.type_id,
            title_id: InputFields.title_id,
            subtitle_id: InputFields.subtitle_id,
            person_type_id: InputFields.person_type_id,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            territory_id: InputFields.territory_id,
            status: InputFields.status,
            image_list: [],
        };

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, MenuNames.observation.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuNames.observation.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.person_type.lower, []);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.observation_type.lower, []);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.observation_title.lower, []);

        CrudBloc.instance.clearList(MenuNames.area.lower);
        CrudBloc.instance.clearList(MenuNames.territory.lower);
        CrudBloc.instance.clearList(MenuNames.observation_subtitle.lower);

    }

    componentDidMount() {

        let formRef = this.formRef;
        if(this.props.edit){
            SettingsBloc.instance.setCurrentPageTitle("Observation Update");
            this.observationSubcription = CrudBloc.instance.observation.subscribe({
                next(x) {
                    if (x){
                        CrudBloc.instance.clearList(MenuNames.area.lower);
                        //CrudBloc.instance.getList({region_id: x.region_id}, MenuNames.area.lower, areaFilterParams)
                        LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.region_id);
                        LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);
                        formRef.current?.setFieldsValue({ region_id: x.region_id });
                        formRef.current?.setFieldsValue({ area_id: x.area_id });
                    }
                },
            });
        }else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Observation");
            const history = this.props.history;
            this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
                next(x) {
                    if (x){
                        history.push(getRouteList(MenuNames.observation.lower));
                        CrudBloc.instance.clearCreateResponseData();
                    }
                },
            });
        }
        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                }
            },
        });
        this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.getList({area_id: x, status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                }
            },
        });
        this.selectTitleSubscription = LocationBloc.instance.selectedObservationTitle.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.observation_subtitle.lower);
                    CrudBloc.instance.getList({title_id: x, status: 1, limit: 100}, MenuNames.observation_subtitle.lower, observation_subtitleFilterParams);
                }
            },
        });

    }
    componentWillUnmount() {
        console.log("Unmount")
        this.observationSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
        this.selectAreaSubscription?.unsubscribe();
        this.selectTitleSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        delete values["region_id"];
        delete values["area_id"];
        values["user_id"] = AuthBloc.instance.user.getValue().email;
        values["gps"] = "web";
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.observation.lower);
        }else{
            let formData = new FormData();
            if(this.state.image_list.length > 0)
                formData.append("image1", this.state.image_list[0].originFileObj);
            if(this.state.image_list.length > 1)
                formData.append("image2", this.state.image_list[1].originFileObj);
            if(this.state.image_list.length > 2)
                formData.append("image3", this.state.image_list[2].originFileObj);
            if(this.state.image_list.length > 3)
                formData.append("image4", this.state.image_list[3].originFileObj);

            console.log(formData)
            axiosWrapper
                .post(BASEURL+"/observation/upload-i",formData)
                .then(res => {
                    console.log(res)
                    values.image1 = res.data.data.image1;
                    values.image2 = res.data.data.image2;
                    values.image3 = res.data.data.image3;
                    values.image4 = res.data.data.image4;
                    console.log(values);
                    CrudBloc.instance.createNew(values, MenuNames.observation.lower);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }

    };

    render() {
         return (
            <IsLoading name={MenuNames.observation.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.observation}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);

                        return !this.props.edit || snapshot.data ?
                            <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className={'observation_form'}>
                                <Row type={"flex"} gutter={16}>
                                    <Col span={8}>
                                        <InputSelect
                                            values={this.state.is_action_taken}
                                            options={InputFieldOptions.is_action_taken}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.is_action_taken.name] : null}
                                        />
                                    </Col> <Col span={8}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.person_typeList}
                                        builder={(snapshotPersonType) => {
                                            return <InputSelect
                                                values={this.state.person_type_id}
                                                options={snapshotPersonType.data ? snapshotPersonType.data.list : []}
                                            />
                                        }}/>
                                </Col></Row>
                                <Row type={"flex"} justify={"space-between"} gutter={16}>
                                    <Col span={12}>
                                        <InputTextArea
                                            values={this.state.action_taken}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.action_taken.name] : null}/>
                                    </Col> <Col span={12}>
                                    <InputTextArea
                                        values={this.state.description}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.description.name] : null}/>
                                </Col></Row>

                                <Row type={"flex"} justify={"space-between"} gutter={16}>
                                    <Col span={8}>
                                        <BlocBuilder
                                            subject={CrudBloc.instance.regionList}
                                            builder={(snapshotRegion) => {
                                                return <InputSelect
                                                    onSelect={(id) => {
                                                        LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);
                                                        this.formRef.current?.setFieldsValue({area_id: undefined});
                                                        this.formRef.current?.setFieldsValue({territory_id: undefined});
                                                    }
                                                    }
                                                    values={this.state.region_id}
                                                    options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                                />
                                            }}/>
                                    </Col><Col span={8}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.areaList}
                                        builder={(snapshotArea) => {

                                            return <InputSelect
                                                onSelect={(id) => {
                                                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, id);
                                                    this.formRef.current?.setFieldsValue({territory_id: undefined});
                                                }
                                                }
                                                values={this.state.area_id}
                                                options={snapshotArea.data ? snapshotArea.data.list : []}
                                            />
                                        }}/>
                                </Col><Col span={8}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.territoryList}
                                        builder={(snapshotTerritory) => {
                                            //console.log(snapshotAgency.data);
                                            return <InputSelect
                                                values={this.state.territory_id}
                                                options={snapshotTerritory.data ? snapshotTerritory.data.list : []}
                                            />
                                        }}/>
                                </Col>
                                </Row>
                                <Row type={"flex"} justify={"space-between"} gutter={16}>
                                    <Col span={8}>
                                        <BlocBuilder
                                            subject={CrudBloc.instance.observation_typeList}
                                            builder={(snapshotType) => {
                                                return <InputSelect
                                                    values={this.state.type_id}
                                                    options={snapshotType.data ? snapshotType.data.list : []}
                                                />
                                            }}/>
                                    </Col><Col span={8}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.observation_titleList}
                                        builder={(snapshotTitle) => {

                                            return <InputSelect
                                                onSelect={(id) => {
                                                    LocationBloc.instance.onSelectLocation(MenuNames.observation_title.upper, id);
                                                    this.formRef.current?.setFieldsValue({subtitle_id: undefined});
                                                }
                                                }
                                                values={this.state.title_id}
                                                options={snapshotTitle.data ? snapshotTitle.data.list : []}
                                            />
                                        }}/>
                                </Col><Col span={8}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.observation_subtitleList}
                                        builder={(snapshotSubtitle) => {
                                            return <InputSelect
                                                values={this.state.subtitle_id}
                                                options={snapshotSubtitle.data ? snapshotSubtitle.data.list : []}
                                            />
                                        }}/>
                                </Col></Row>
                                <Row type={"flex"} justify={"space-between"} gutter={16}>
                                    <Col span={12}>
                                        <InputText
                                            values={this.state.address}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.address.name] : null}/>
                                    </Col> <Col span={12}>

                                </Col></Row>
                                <Row type={"flex"} justify={"space-between"} gutter={16}>
                                    <Col span={6}>
                                        <TextX text={"Image List: "}/>
                                        <Upload
                                            accept={".png,.jpg"}
                                            maxCount={1}
                                            name="images"
                                            listType="picture"
                                            fileList={this.state.image_list}
                                            beforeUpload={() => false}
                                            onChange={this.handleUploadPhoto}
                                        >
                                            <Button icon={<UploadOutlined/>}>Click to upload</Button>
                                        </Upload>
                                    </Col>
                                </Row>
                                <Box y={30}/>
                                <Row type={"flex"} gutter={16} justify={"center"}>
                                    <Col span={8}>
                                <Form.Item>
                                    <ButtonX
                                        htmlType="submit"
                                        name="submit"
                                        text="Submit" className={"button-default-accent"}/>
                                </Form.Item>
                                    </Col></Row>
                                <BlocBuilder
                                    subject={AuthBloc.instance.errorText}
                                    builder={(snapshotError) => {
                                        //console.log(snapshotError.data);
                                        return <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                    }}/>
                            </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
    handleUploadPhoto = ({fileList }) => {
        console.log('fileList', fileList);
        this.setState({ image_list: fileList });
    };
}