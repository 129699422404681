import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {person_typeDetailsAttributes} from "./PersonTypeConstants";
import {MenuNames} from "../../utils/Constants";

export class PersonTypeDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("PersonType Details")
        CrudBloc.instance.getDetails(id, MenuNames.person_type.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.person_type}
            builder = {(snapshot) => {
                console.log(snapshot.data);
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={person_typeDetailsAttributes}
                    name={MenuNames.person_type.lower}
                />
            }}/>
    }
}

