export const tableContentType = {
    DATE_TIME: "DATE_TIME",
    TIME_AGO: "TIME_AGO",
    ID: "ID",
    LINK: "LINK",
    PRICE: "PRICE",
    PRODUCT: "PRODUCT",
    TITLE: "TITLE",
    INDICATOR: "INDICATOR",
    TEXT: "TEXT",
    STATUS: "STATUS",
    COLLABORATOR_STATUS: "COLLABORATOR_STATUS",
    APP_NAME: "APP_NAME",
    ACTION: "ACTION",
    EMPTY: "EMPTY",
    POP_OVER: "POP_OVER",
    EMAIL: "EMAIL",
    SHIMMER: "SHIMMER",
    IMAGE: "IMAGE",
    IS_ACTION_TAKEN: "IS_ACTION_TAKEN"
};