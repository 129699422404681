import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, Row, Spin} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import LocationBloc from "../../bloc/LocationBloc";
import {areaFilterParams} from "../area/AreaConstants";

import {InputPassword} from "../shared/FormComponent/InputPassword";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";

export class UserCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        //console.log(props);

        this.state = {
            email: InputFields.email,
            name: InputFields.name,
            password: InputFields.password,
            mobile: InputFields.mobile,
            department_id: InputFields.department_id,
            line_manager: InputFields.line_manager,
            employee_id: InputFields.employee_id,
            designation: InputFields.designation,
            user_group_id: InputFields.user_group_id
        };

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, MenuNames.user.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuNames.user.lower);
        }
        CrudBloc.instance.getList("", MenuNames.user_group.lower, [])
        CrudBloc.instance.getList({limit: 100, status: 1}, MenuNames.department.lower, [])
    }

    componentDidMount() {
        if(this.props.edit){
            SettingsBloc.instance.setCurrentPageTitle("User Update");
        }else {
            SettingsBloc.instance.setCurrentPageTitle("Create new User");
            const history = this.props.history;
            this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
                next(x) {
                    if (x){
                        history.push(getRouteList(MenuNames.user.lower));
                        CrudBloc.instance.clearCreateResponseData();
                    }
                },
            });
            this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
                next(x) {
                    if (x){
                        CrudBloc.instance.clearList(MenuNames.area.lower);
                        CrudBloc.instance.getList({region_id: x}, MenuNames.area.lower, areaFilterParams)
                    }
                },
            });
            this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
                next(x) {
                    if (x){
                        CrudBloc.instance.clearList(MenuNames.town.lower);
                       // CrudBloc.instance.getList({area_id: x}, MenuNames.town.lower, townFilterParams)
                    }
                },
            });
        }
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.user.lower);
        }else{
            values.c_password = values.password;
            CrudBloc.instance.createNew(values, MenuNames.user.lower);
        }

    };

    render() {
         return (
            <IsLoading name={MenuNames.user.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.user}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ?
                            <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className={'observation_form'}>
                            <Row type={"flex"} gutter={16}>
                                <Col span={8}>
                            <InputText
                                values={this.state.email}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.email.name] : null}/>
                                </Col>
                                <Col span={8}>
                            <InputText
                                values={this.state.name}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : null}/>
                                </Col></Row>
                            <Row type={"flex"} gutter={16}>
                                <Col span={8}>
                            <InputPassword
                                values={this.state.password}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.password.name] : null}/>
                                </Col><Col span={8}>
                            <InputText
                                values={this.state.mobile}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : null}/>
                            </Col></Row>
                            <Row type={"flex"} gutter={16}>
                                <Col span={5}>
                            <InputText
                                values={this.state.employee_id}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.employee_id.name] : null}/>
                                </Col><Col span={5}>
                                <InputText
                                    values={this.state.designation}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.designation.name] : null}/>
                            </Col><Col span={6}>
                            <InputText
                                values={this.state.line_manager}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.line_manager.name] : null}/>
                            </Col></Row>
                            <Row type={"flex"} gutter={16}>
                                <Col span={8}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.user_groupList}
                                builder = {(snapshotUserGroup) => {
                                    return  <InputSelect
                                        values={this.state.user_group_id}
                                        options={snapshotUserGroup.data ? snapshotUserGroup.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.user_group_id.name] : null}
                                    />
                                }}/>
                                </Col><Col span={8}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.departmentList}
                                builder = {(snapshotDepartment) => {
                                    return  <InputSelect
                                        values={this.state.department_id}
                                        options={snapshotDepartment.data ? snapshotDepartment.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.department_id.name] : null}
                                    />
                                }}/>
                            </Col></Row>
                            <Box y={10}/>
                            <Row type={"flex"} gutter={16}>
                                <Col span={16}>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                                </Col></Row>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form>
                            : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}