import * as rxjs from "rxjs";
import LoadingBloc, {LoadingContent} from "./LoadingBloc";
import {prepareUrlWithFilter} from "../utils/Utils";
import axiosWrapper from "../utils/AxiosWrapper";
import {BASEURL} from "../utils/Constants";
import {getApiList, statisticsMonthPath, statisticsYearPath} from "../utils/RouterUtils";
import ErrorBloc from "./ErrorBloc";

export default class StatisticsBloc{

    static instance = new StatisticsBloc();


    constructor(){
        this.statisticsMonth = new rxjs.BehaviorSubject([]);
        this.statisticsYear = new rxjs.BehaviorSubject([]);

    }

    getMonthStat(){
        LoadingBloc.instance.start(LoadingContent.STATISTICS_MONTH);

        axiosWrapper
            .get(BASEURL+ statisticsMonthPath)
            .then((response) => {
                LoadingBloc.instance.end(LoadingContent.STATISTICS_MONTH);
                this.statisticsMonth.next(response.data.data);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(LoadingContent.STATISTICS_MONTH);
            });
    }

    getYearStat(){
        LoadingBloc.instance.start(LoadingContent.STATISTICS_YEAR);

        axiosWrapper
            .get(BASEURL+ statisticsYearPath)
            .then((response) => {
                LoadingBloc.instance.end(LoadingContent.STATISTICS_YEAR);
                this.statisticsYear.next(response.data.data);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(LoadingContent.STATISTICS_YEAR);
            });
    }

    error = (error) => {
        console.log(error);
    };

}