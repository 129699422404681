export const InputFieldOptions = {
    status: [
        {id: 1, title: "Active"},
        {id: 2, title: "In-Active"},
    ],

    is_action_taken: [
        {id: 1, title: "Yes"},
        {id: 2, title: "No"},
    ]
}

