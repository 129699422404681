import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Col, Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {Box} from "../shared/Box";
import {observationFilterParams, observationListAttributes, observationQueryFilterParams} from "./ObservationConstants";
import {MenuNames} from "../../utils/Constants";
import {regionFilterParams} from "../region/RegionConstants";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import {getRouteCreate} from "../../utils/RouterUtils";
import {ButtonExport} from "../shared/ButtonExport";
import {RangeDateTimePicker} from "../shared/DateTime/RangeDateTimePicker";
import Utils from "../../utils/Utils";
import AuthBloc from "../../bloc/AuthBloc";

export class ObservationListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("Observation List")
        const query = new URLSearchParams(this.props.location.search);

        const from = query.get('from');
        const to = query.get('to');
        let dateRange = Utils.getDefaultDateRange(from, to)

        let searchParams = new URLSearchParams(this.props.location.search);
        if(!AuthBloc.instance.isAdmin()) {
            searchParams.set("user_id", AuthBloc.instance.user.getValue().email);
        }
        CrudBloc.instance.getList(searchParams.toString(), MenuNames.observation.lower, observationFilterParams, dateRange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        const query = new URLSearchParams(this.props.location.search);

        if(!AuthBloc.instance.isAdmin()) {
            query.set("user_id", AuthBloc.instance.user.getValue().email);
        }

        const from = query.get('from');
        const to = query.get('to');
        let dateRange = Utils.getDefaultDateRange(from, to)

        console.log(dateRange);
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(query.toString(), MenuNames.observation.lower, observationFilterParams, dateRange);
        }
    }

    onDateChanged = range => {
        const between = {from: range.from, to: range.to};
        const {history} = this.props;
        let pathName = this.props.location.pathname;
        let searchParams = new URLSearchParams(this.props.location.search);

        searchParams.set("from", between.from);
        searchParams.set("to", between.to);

        history.push({
            pathName: pathName,
            search: searchParams.toString()
        });
    };

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                <ButtonCreateNew to={getRouteCreate(MenuNames.observation.lower)} forcedEnable={true}/>
                <ButtonExport search={this.props.location.search} name={MenuNames.observation.lower} param={observationFilterParams} forcedEnable={true}/>
                <Col span={6}>
                    <RangeDateTimePicker style={{height: 36}}
                         onDateChanged={this.onDateChanged}
                         defaultInterval={30}
                         location={this.props.location}
                         history={this.props.history}/>
                </Col>
                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {observationFilterParams}
                    queryFilterParams = {observationQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.observationList}
            builder = {(snapshot) => {
                console.log(snapshot.data);
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={observationListAttributes}
                    name={MenuNames.observation.lower}
                />
            }}/>
        </div>
    }
}

