import {prepareInput} from "./Utils";
import {FileTextOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";

export const InputFields = {
    email: prepareInput({
        name: 'email',
        label:  'Email Address',
        placeholder: 'Email Address',
        rules:  [{ required: true, type: "email",message: 'Please input your email!' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    name: prepareInput({
        name: 'name',
        label:  'Name',
        placeholder: 'Name',
        rules:  [{ required: true, message: 'Please input your name!' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    password: prepareInput({
        name: 'password',
        label: 'Password',
        rules: [{ required: true, message: 'Please input your Password!' }],
        placeholder: 'Enter Password',
        type: "password",
        prefixIcon: <LockOutlined className="site-form-item-icon" />}),
    title: {
        name: 'title',
        label:  'Title',
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    action_taken: {
        name: 'action_taken',
        label:  'Action Taken',
        placeholder: '',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    description: {
        name: 'description',
        label:  'Description',
        placeholder: 'Description',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    line_manager: {
        name: 'line_manager',
        label:  'Line Manager Email',
        placeholder: 'e.g. a@a.com',
        rules:  [{ required: true,type:"email", message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    employee_id: {
        name: 'employee_id',
        label:  'Employee Id',
        placeholder: 'employee id',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    designation: {
        name: 'designation',
        label:  'Designation',
        placeholder: 'e.g. RM, TM etc ',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    region_id: {
        name: 'region_id',
        label: 'Region',
        placeholder: 'Select region',
        rules:  [{ required: true, message: 'Please select region!' }],
        options: []
    },
    area_id: {
        name: 'area_id',
        label: 'Area',
        placeholder: 'Select area',
        rules:  [{ required: true, message: 'Please select area!' }],
        options: []
    },
    territory_id: {
        name: 'territory_id',
        label: 'Territory',
        placeholder: 'Select territory',
        rules:  [{ required: true, message: 'Please select territory!' }],
        options: []
    },
    department_id: {
        name: 'department_id',
        label: 'Department',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },

    type_id: {
        name: 'type_id',
        label: 'Observation Type',
        placeholder: 'Select Type',
        rules:  [{ required: true, message: 'Please select Type!' }],
        options: []
    },
    title_id: {
        name: 'title_id',
        label: 'Observation Title',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },
    subtitle_id: {
        name: 'subtitle_id',
        label: 'Observation Subtitle',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },
    person_type_id: {
        name: 'person_type_id',
        label: 'Person Type',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },
    is_action_taken: {
        name: 'is_action_taken',
        label: 'Is Action Taken',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },
    user_group_id: {
        name: 'user_group_id',
        label: 'User Group',
        placeholder: 'Select user group',
        rules:  [{ required: true, message: 'Please select User group' }],
        options: []
    },
    mobile: {
        name: 'mobile',
        label:  'Mobile Number',
        placeholder: '01...',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    address: {
        name: 'address',
        label:  "Address",
        placeholder: 'Address',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },

    photo: {
        name: 'photo',
        label:  "Photograph",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    status: {
        name: 'status',
        label:  "Status",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select status' }],
        prefixIcon: <FileTextOutlined />
    },
};

export const resetFields = () =>  {
    Object.keys(InputFields).forEach(function(key) {
        console.log(key);
        if(key === 'status' || key === 'verified'){

        }else if(InputFields[key].value) {
            InputFields[key].value = "";
        }
    });
};