import React from "react";
import SettingsBloc from "../../bloc/SettingsBloc";
import StatisticsBloc from "../../bloc/StatisticsBloc";
import {Bar, Column, Pie} from "@ant-design/charts";
import BlocBuilder from "bloc-builder-react/src";


export class HomePage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("Home")
        StatisticsBloc.instance.getMonthStat();
        StatisticsBloc.instance.getYearStat();
    }

    render() {

        return <div>
            <BarChartMonth/>
            <BarChartYear/>
            <PieChartMonth/>
            <PieChartYear/>
        </div>
    }
}

function BarChartMonth(){
    return <BlocBuilder
        subject = {StatisticsBloc.instance.statisticsMonth}
        builder = {(snapshot) => {
            const config =  {
                data: snapshot.data ?? [],
                xField: 'title',
                yField: 'count',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                }
            };
            return  <Column  {...config} />
        }}/>
}

function BarChartYear(){
    return <BlocBuilder
        subject = {StatisticsBloc.instance.statisticsYear}
        builder = {(snapshot) => {
            const config =  {
                data: snapshot.data ?? [],
                xField: 'title',
                yField: 'count',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                }
            };
            return  <Column  {...config} />
        }}/>
}

function PieChartMonth(){
    return <BlocBuilder
        subject = {StatisticsBloc.instance.statisticsMonth}
        builder = {(snapshot) => {
            const config = {
                appendPadding: 10,
                data: snapshot.data ?? [],
                angleField: 'count',
                colorField: 'title',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    content: function content(_ref) {
                        var percent = _ref.percent;
                        return ''.concat(percent * 100, '%');
                    },
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [{ type: 'element-active' }],
            };
            return  <Pie   {...config} />
        }}/>
}

function PieChartYear(){
    return <BlocBuilder
        subject = {StatisticsBloc.instance.statisticsYear}
        builder = {(snapshot) => {
            const config = {
                appendPadding: 10,
                data: snapshot.data ?? [],
                angleField: 'count',
                colorField: 'title',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    content: function content(_ref) {
                        var percent = _ref.percent;
                        return ''.concat(percent * 100, '%');
                    },
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [{ type: 'element-active' }],
            };
            return  <Pie   {...config} />
        }}/>
}