export const Colors = {
    cool_grey: '#8d9ba6',
    black: '#101010',
    black_three: '#141414',
    dark_seafoam_green: '#3bb278',
    black_two: '#131313',
    slate_grey: '#5a6872',
    dodger_blue: '#2f9cf5',
    pale_grey: '#dee6ea',
    white: '#ffffff',
    dark_mint: '#46c486',
    cool_grey_two: '#a3afb8',
    pale_grey_two: '#dfe6ea',
    pine: '#387758',
    battleship_grey: '#6c727b',
    dark_slate_blue: '#252e73',
    ice_blue: '#fafdff',
    light_sky_blue: '#d3ebff',
    ice: '#e8fff4',
    cloudy_blue: '#d4dde5',
    cloudy_blue_10: '#e7eef5',
    water_blue: '#1a82d7',
    greyblue: '#8dabc4',
    light_blue_grey: '#a8c6df',
    deep_sky_blue: '#007fff',
    charcoal_grey: '#323a3e',
    black_four: '#111111',
    slate_grey_two: '#585f73',
    dark: '#0d101a',
    off_white: '#f5faf4',
    algae_green_50: 'rgba(31, 187, 100, 0.5)',
    very_light_pink_0: 'rgba(216, 216, 216, 0)',
    slate: '#566973',
    cool_grey_three: '#979fa5',
    pale_grey_five: '#f8fbff',
    white_three: '#d8d8d8',
    algae_green: '#1fbb64',
    pale_red: '#dd4747'
};
