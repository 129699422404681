import React from 'react';
import {DatePicker} from 'antd';
import moment from 'moment';
import './RangeDateTimePicker.css';
import Utils from "../../../utils/Utils";


const {RangePicker} = DatePicker;

export const componentType = {
  CHART: "CHART",
  LIST: "LIST",
};

export class RangeDateTimePicker extends React.Component {
  constructor(props) {
    super(props);

	  const query = new URLSearchParams(this.props.location.search);
	  const from = query.get('from');
	  const to = query.get('to');
	  const between = Utils.getDefaultDateRange(from, to);

    this.state = {
      start: moment(between.from, "YYYY-MM-DDTHH:mm:ssZ"),
      end: moment(between.to, "YYYY-MM-DDTHH:mm:ssZ"),
      dateChanged: false,
      appliedValue: "",
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

	  if(prevProps.location !== this.props.location) {
		 this.resetDateTime();
	  }
  }

  resetDateTime() {
	  const query = new URLSearchParams(this.props.location.search);
	  const from = query.get('from');
	  const to = query.get('to');
	  const between = Utils.getDefaultDateRange(from, to);
	  this.setState({ start: moment(between.from, "YYYY-MM-DDTHH:mm:ssZ"),
		  end: moment(between.to, "YYYY-MM-DDTHH:mm:ssZ"),});

  }

    applyCallback = (from, to)=> {
    	this.setState(
    		{
    			start: from,
    			end: to,
    			dateChanged: this.state.appliedValue !== "Reset",
    		},
    		() => {
    			this.props.onDateChanged({
    				from:
                        moment(from)
                        	.utc()
                        	.format("YYYY-MM-DDTHH:mm:ss") + "Z",
    				to:
                        moment(to)
                        	.utc()
                        	.format("YYYY-MM-DDTHH:mm:ss") + "Z",
    			});
    		}
    	);
    };

    rangeCallback = (index, value)=> {
    	this.setState({appliedValue: value});
    };

    onClear = ()=> {
    	let {startOfToday, currentDateTime} = this.resetDateTime();
    	let end =
            this.props.type === componentType.CHART
            	? moment(startOfToday).subtract(1, "seconds")
            	: currentDateTime;
    	let start = moment(
    		this.props.type === componentType.CHART
    			? startOfToday
    			: currentDateTime
    	).subtract(this.props.defaultInterval, "days");

    	this.setState(
    		{
    			start: start,
    			end: end,
    			dateChanged: false,
    		},
    		() => {
    			this.props.onDateChanged({
    				from:
                        moment(start)
                        	.utc()
                        	.format("YYYY-MM-DDTHH:mm:ss") + "Z",
    				to:
                        moment(end)
                        	.utc()
                        	.format("YYYY-MM-DDTHH:mm:ss") + "Z",
    			});
    		}
    	);
    };

    onChange = (value) => {
    	console.log(value);
      if(value !=null && value.length === 2 && value[0] && value[1])
    		this.applyCallback(value[0], value[1]);
      else {
        const between = Utils.getDefaultDateRange();
        this.applyCallback(moment(between.from, "YYYY-MM-DDTHH:mm:ssZ"), moment(between.to, "YYYY-MM-DDTHH:mm:ssZ"));
      }
    };

    render() {
    	const {format} = this.props;
    	let now = new Date();
    	let startOfToday = moment(
    		new Date(
    			now.getFullYear(),
    			now.getMonth(),
    			now.getDate(),
    			0,
    			0,
    			0,
    			0
    		)
    	);
    	let currentDateTime = moment(
    		new Date(
    			now.getFullYear(),
    			now.getMonth(),
    			now.getDate(),
    			now.getHours(),
    			now.getMinutes(),
    			0,
    			0
    		)
    	);
    	let ranges = {
    		// "30 Minutes": [
    		// 	moment(currentDateTime).subtract(30, "minutes"),
    		// 	moment(currentDateTime),
    		// ],
    		// "3 Hours": [
    		// 	moment(currentDateTime).subtract(3, "hours"),
    		// 	moment(currentDateTime),
    		// ],
    		// "6 Hours": [
    		// 	moment(currentDateTime).subtract(6, "hours"),
    		// 	moment(currentDateTime),
    		// ],
    		// Today: [moment(startOfToday), moment(currentDateTime)],
    		// Yesterday: [
    		// 	moment(startOfToday).subtract(1, "days"),
			// 	moment(currentDateTime),
    		// ],
    		// "3 Days": [
    		// 	moment(startOfToday).subtract(3, "days"),
			// 	moment(currentDateTime),
    		// ],
    		"7 Days": [
    			moment(startOfToday).subtract(7, "days"),
				moment(currentDateTime),
    		],
    		"30 Days": [
    			moment(startOfToday).subtract(30, "days"),
				moment(currentDateTime),
    		],
        "60 Days": [
          moment(startOfToday).subtract(60, "days"),
			moment(currentDateTime),
        ],
			"6 Months": [
				moment(startOfToday).subtract(6, "months"),
				moment(currentDateTime),
			],
			"1 Year": [
				moment(startOfToday).subtract(1, "year"),
				moment(currentDateTime),
			],
			"5 years": [
				moment(startOfToday).subtract(5, "years"),
				moment(currentDateTime),
			],
    		Reset: [
    		    moment(
    		        this.props.type === componentType.CHART
    		            ? startOfToday
    		            : currentDateTime
    		    ).subtract(this.props.defaultInterval, "days"),
    		    moment(
    		        this.props.type === componentType.CHART
    		            ? moment(startOfToday).subtract(1, "seconds")
    		            : currentDateTime
    		    ),
    		],
    	};
    	return (
    		<RangePicker
    			allowClear={true}
    			defaultValue={[this.state.start, this.state.end]}
          		value={[this.state.start, this.state.end]}
    			key="RangePicker"
    			ranges={ranges}
    			showTime = {false}
    			format={format ? format : " MMM DD, YYYY"}
    			onChange={this.onChange}
    		/>
    	);
    }
}
