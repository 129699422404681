import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";
export const observationQueryFilterParams = () => observationFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const observationFilterParams = [
  FilterInputItems.id,
  FilterInputItems.title,
  FilterInputItems.region_id,
  FilterInputItems.area_id,
  FilterInputItems.user_id,
];

export const observationListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Action',
    field: 'action_taken',
    type: tableContentType.TEXT,
  },
  {
    title: 'Description',
    field: 'description',
    type: tableContentType.TEXT,
  },
  {
    title: 'User Email',
    field: 'user_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Type',
    field: 'type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const observationDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Action',
    field: 'action_taken',
    type: tableContentType.TEXT,
  },
  {
    title: 'Description',
    field: 'description',
    type: tableContentType.TEXT,
  },
  {
    title: 'User Email',
    field: 'user_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Type',
    field: 'type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Observation Title',
    field: 'title_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Observation Subtitle',
    field: 'subtitle_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Person Type',
    field: 'person_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'GPS',
    field: 'gps',
    type: tableContentType.TEXT,
  },
  {
    title: 'Is Action Taken',
    field: 'is_action_taken',
    type: tableContentType.IS_ACTION_TAKEN,
  },
  {
    title: 'Image 1',
    field: 'image1',
    type: tableContentType.IMAGE,
  },
  {
    title: 'Image 2',
    field: 'image2',
    type: tableContentType.IMAGE,
  },
  {
    title: 'Image 3',
    field: 'image3',
    type: tableContentType.IMAGE,
  },
  {
    title: 'Image 4',
    field: 'image4',
    type: tableContentType.IMAGE,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

