import React from "react";
import BlocBuilder from "bloc-builder-react/src";

import AuthBloc from "../../bloc/AuthBloc";
import {Button, Col, Form, Row, Spin, Upload} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {FormSkeleton} from "../shared/FormSkeleton";
import {UploadOutlined} from "@ant-design/icons";
import {BASEURL} from "../../utils/Constants";
import axiosWrapper from "../../utils/AxiosWrapper";

export class ImageUpload extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        console.log(AuthBloc.instance.user.getValue());

        this.state = {
            image1_list: [],
            // image2_list: [],
            // image3_list: [],
            // image4_list: [],
        };
    }




    onFinish = (values) => {
        console.log('Success:', values);

        let formData = new FormData();
        if(this.state.image1_list.length > 0)
        formData.append("image1", this.state.image1_list[0].originFileObj);
        if(this.state.image1_list.length > 1)
        formData.append("image2", this.state.image1_list[1].originFileObj);
        if(this.state.image1_list.length > 2)
        formData.append("image3", this.state.image1_list[2].originFileObj);
        if(this.state.image1_list.length > 3)
        formData.append("image4", this.state.image1_list[3].originFileObj);

        console.log(formData)
        axiosWrapper
            .post(BASEURL+"/observation/upload-i",formData)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("err", err);
            });

    };

    render() {
        return  <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical">
            <Row type={"flex"} justify={"space-between"} gutter={16}>
                <Col span={6}>
                    <TextX text={"Image 1: "}/>

                    <Upload
                        accept={".png,.jpg"}
                        maxCount={4}
                        name="image1"
                        listType="picture"
                        fileList={this.state.image1_list}
                        beforeUpload={() => false}
                        onChange={this.handleUploadPhoto}
                    >
                        <Button icon={<UploadOutlined/>}>Click to upload</Button>
                    </Upload>
                    {/*<BlocBuilder*/}
                    {/*    subject={ProfileBloc.instance.errorTextPhoto}*/}
                    {/*    builder={(snapshotError) => {*/}
                    {/*        return <TextX text={snapshotError.data} color={Colors.pale_red}/>*/}
                    {/*    }}/>*/}

                </Col>
                {/*<Col span={6}>*/}
                {/*    <TextX text={"Image 2: "}/>*/}

                {/*    <Upload*/}
                {/*        accept={".png,.jpg"}*/}
                {/*        maxCount={1}*/}
                {/*        name="image2"*/}
                {/*        listType="picture"*/}
                {/*        fileList={this.state.image2_list}*/}
                {/*        beforeUpload={() => false}*/}
                {/*        onChange={this.handleUploadPhoto}*/}
                {/*    >*/}
                {/*        <Button icon={<UploadOutlined/>}>Click to upload</Button>*/}
                {/*    </Upload>*/}

                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <TextX text={"Image 3: "}/>*/}

                {/*    <Upload*/}
                {/*        accept={".png,.jpg"}*/}
                {/*        maxCount={1}*/}
                {/*        name="image3"*/}
                {/*        listType="picture"*/}
                {/*        fileList={this.state.image3_list}*/}
                {/*        beforeUpload={() => false}*/}
                {/*        onChange={this.handleUploadPhoto}*/}
                {/*    >*/}
                {/*        <Button icon={<UploadOutlined/>}>Click to upload</Button>*/}
                {/*    </Upload>*/}

                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <TextX text={"Image 4: "}/>*/}

                {/*    <Upload*/}
                {/*        accept={".png,.jpg"}*/}
                {/*        maxCount={1}*/}
                {/*        name="image4"*/}
                {/*        listType="picture"*/}
                {/*        fileList={this.state.image4_list}*/}
                {/*        beforeUpload={() => false}*/}
                {/*        onChange={this.handleUploadPhoto}*/}
                {/*    >*/}
                {/*        <Button icon={<UploadOutlined/>}>Click to upload</Button>*/}
                {/*    </Upload>*/}

                {/*</Col>*/}

            </Row>
            <Box y={30}/>
            <Row type={"flex"} gutter={16} justify={"center"}>
                <Col span={8}>
                    <Form.Item>
                        <ButtonX
                            htmlType="submit"
                            name="submit"
                            text="Submit" className={"button-default-accent"}/>
                    </Form.Item>
                </Col></Row>
            <BlocBuilder
                subject={AuthBloc.instance.errorText}
                builder={(snapshotError) => {
                    //console.log(snapshotError.data);
                    return <TextX text={snapshotError.data} color={Colors.water_blue}/>
                }}/>
        </Form>
    }
    handleUploadPhoto = ({fileList }) => {
        console.log('fileList', fileList);
        this.setState({ image1_list: fileList });
    };
}