import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import PropTypes from 'prop-types'
import {
    getRouteCreate,
    getRouteDetailsPath,
    getRouteList,
    getRouteUpdatePath,
    routeHome
} from "../../utils/RouterUtils";
import {HomePage} from "../home/HomePage";
import {RegionListPage} from "../region/RegionListPage";
import {RegionDetailsPage} from "../region/RegionDetailsPage";
import {AreaDetailsPage} from "../area/AreaDetailsPage";
import {AreaListPage} from "../area/AreaListPage";
import {UserListPage} from "../user/UserListPage";
import {UserDetailsPage} from "../user/UserDetailsPage";
import {RegionCreatePage} from "../region/RegionCreatePage";
import {AreaCreatePage} from "../area/AreaCreatePage";
import {UserCreatePage} from "../user/UserCreatePage";
import {MenuNames} from "../../utils/Constants";
import {ObservationTypeListPage} from "../observation_type/ObservationTypeListPage";
import {ObservationTypeCreatePage} from "../observation_type/ObservationTypeCreatePage";
import {ObservationTypeDetailsPage} from "../observation_type/ObservationTypeDetailsPage";
import {ObservationTitleListPage} from "../observation_title/ObservationTitleListPage";
import {ObservationTitleCreatePage} from "../observation_title/ObservationTitleCreatePage";
import {ObservationTitleDetailsPage} from "../observation_title/ObservationTitleDetailsPage";
import {ObservationSubtitleListPage} from "../observation_subtitle/ObservationSubtitleListPage";
import {ObservationSubtitleCreatePage} from "../observation_subtitle/ObservationSubtitleCreatePage";
import {ObservationSubtitleDetailsPage} from "../observation_subtitle/ObservationSubtitleDetailsPage";
import {PersonTypeListPage} from "../person_type/PersonTypeListPage";
import {PersonTypeCreatePage} from "../person_type/PersonTypeCreatePage";
import {PersonTypeDetailsPage} from "../person_type/PersonTypeDetailsPage";
import {UserGroupListPage} from "../user_group/UserGroupListPage";
import {UserGroupCreatePage} from "../user_group/UserGroupCreatePage";
import {UserGroupDetailsPage} from "../user_group/UserGroupDetailsPage";
import {TerritoryListPage} from "../territory/TerritoryListPage";
import {TerritoryCreatePage} from "../territory/TerritoryCreatePage";
import {TerritoryDetailsPage} from "../territory/TerritoryDetailsPage";
import {ObservationListPage} from "../observation/ObservationListPage";
import {ObservationCreatePage} from "../observation/ObservationCreatePage";
import {ObservationDetailsPage} from "../observation/ObservationDetailsPage";
import {ImageUpload} from "../test/ImageUpload";
import {DepartmentListPage} from "../department/DepartmentListPage";
import {DepartmentCreatePage} from "../department/DepartmentCreatePage";
import {DepartmentDetailsPage} from "../department/DepartmentDetailsPage";

class MainWindow extends Component {
    render() {
        const location = this.props.location
        return (
           <div style={{overflowY:"auto", height:"100%"}}>
               <Switch>

                   <Route location={location} path={routeHome} component={HomePage} />


                   <Route location={location} path={getRouteList(MenuNames.region.lower)} component={RegionListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.region.lower)} component={RegionCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.region.lower)} render = {(props) => <RegionCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.region.lower)} component={RegionDetailsPage}/>


                   <Route location={location} path={getRouteList(MenuNames.area.lower)} component={AreaListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.area.lower)} component={AreaCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.area.lower)} render = {(props) => <AreaCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.area.lower)} component={AreaDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.territory.lower)} component={TerritoryListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.territory.lower)} component={TerritoryCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.territory.lower)} render = {(props) => <TerritoryCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.territory.lower)} component={TerritoryDetailsPage}/>

                   l
                   <Route location={location} path={getRouteList(MenuNames.user.lower)} component={UserListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.user.lower)} component={UserCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.user.lower)} render = {(props) => <UserCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.user.lower)} component={UserDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.user_group.lower)} component={UserGroupListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.user_group.lower)} component={UserGroupCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.user_group.lower)} render = {(props) => <UserGroupCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.user_group.lower)} component={UserGroupDetailsPage}/>


                   <Route location={location} path={getRouteList(MenuNames.observation_type.lower)} component={ObservationTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.observation_type.lower)} component={ObservationTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.observation_type.lower)} render = {(props) => <ObservationTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.observation_type.lower)} component={ObservationTypeDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.observation_title.lower)} component={ObservationTitleListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.observation_title.lower)} component={ObservationTitleCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.observation_title.lower)} render = {(props) => <ObservationTitleCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.observation_title.lower)} component={ObservationTitleDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.observation_subtitle.lower)} component={ObservationSubtitleListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.observation_subtitle.lower)} component={ObservationSubtitleCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.observation_subtitle.lower)} render = {(props) => <ObservationSubtitleCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.observation_subtitle.lower)} component={ObservationSubtitleDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.person_type.lower)} component={PersonTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.person_type.lower)} component={PersonTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.person_type.lower)} render = {(props) => <PersonTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.person_type.lower)} component={PersonTypeDetailsPage}/>


                   <Route location={location} path={getRouteList(MenuNames.department.lower)} component={DepartmentListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.department.lower)} component={DepartmentCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.department.lower)} render = {(props) => <DepartmentCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.department.lower)} component={DepartmentDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.observation.lower)} component={ObservationListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.observation.lower)} component={ObservationCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.observation.lower)} render = {(props) => <ObservationCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.observation.lower)} component={ObservationDetailsPage}/>

                   <Route location={location} path={'/image_upload'} component={ImageUpload} />
                   <Route location={location} path={'/'} component={HomePage} />

                   {/*_add_from_here*/}
                   {/*<Route location={location} component={Home} />*/}
               </Switch>
           </div>

        )
    }
}

MainWindow.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MainWindow